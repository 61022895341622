import React from "react"
import {Link, graphql} from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Img from 'gatsby-image';

class BlogIndex extends React.Component {
    render() {
        const {data} = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const posts = data.allMarkdownRemark.edges;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title="All posts"/>
                <Bio/>
                {posts.map(({node}) => {
                    const title = node.frontmatter.title || node.fields.slug;
                    return (
                        <article key={node.fields.slug} className="card">
                            <Link to={node.fields.slug}>
                                <header>
                                    <small className="caption">{node.frontmatter.date}</small>
                                    <h3>{title}</h3>
                                    {node.frontmatter.featuredImage &&
                                    <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid}/>}
                                </header>
                                <section>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: node.frontmatter.description || node.excerpt,
                                        }}
                                    />
                                </section>
                            </Link>
                        </article>
                    )
                })}
            </Layout>
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 630, maxHeight: 400, cropFocus: NORTH) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
